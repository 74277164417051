/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
.bg-dark {
  background-color: #343431 !important;
}

.bg-white {
  background-color: hsl(0, 0%, 100%) !important;

}

.cobrand-banner-image {
  padding-top: 25px;
  margin-left: 20px;
}

.eth-pt-20 {
  padding-top: 20px;
}

.report-container {
    height: 78vh;
    /* Remove child iframe lineheight to prevent overflow */
    line-height: 0px;
    background: white;
}

/* Comment this as we dont need scroll bar if the text is overflowing */
/* .mat-option-text.mat-option-text {
    overflow: auto;
    text-overflow: unset;
} */

.disable-content {
    pointer-events: none;
    cursor: not-allowed;
    opacity: .7;
}

.underline-tab {
    border-bottom: 3px solid white;
}

#merchants-list .mat-form-field-appearance-fill .mat-form-field-flex{
  padding-top: 0em !important;
  width: 200% !important;
}

#merchants-list .mat-form-field-underline{
    width: 200% !important;
}

#onboardMerchant .dataTables_scrollHeadInner, .table{
    width: 100% !important
}

.toast-container .fa-exclamation-circle:before {
  position: absolute !important;
  padding: 18px 14px 18px 18px;
  content: url(./assets/images/error.svg) !important;
}

.toast-container{
  top: 130px !important;
}

.toast-container .ngx-toastr{
  padding: 0px 0px 0px 0px !important;
  width: auto !important;
  max-width: 50%;
  display: table;
  top: 7px !important;
  border-radius: 4px !important;
}

.toast-container .fa-check-circle::before {
  position: absolute !important;
  padding: 18px 14px 18px 18px;
  content: url(./assets/images/success.svg) !important;
}

.toast-container .toast-info {
  background-image: url(./assets/images/informational.svg) !important;
  background-color: #fff4d1 !important;
  color: #332805 !important;
}

.notify-error{
  color: #B00606 !important;
  background-color: #FADEDE !important;
}

.notify-success {
  color: #097007 !important;
  background-color: #DFF7DF !important;
}


.toast-message {
  padding: 16px 72px 16px 52px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: MarkForMC,-apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Arial,sans-serif;
  font-style: normal;
}

.notify-error button, .notify-success button, .toast-info button{
  top: 16px !important;
  right: 16px !important;
  bottom: 16px !important;
  width: 24px !important;
  height: 24px !important;
  background-repeat: no-repeat !important;
  border: 0 !important;
}

.notify-error button{
  background-image: url(./assets/images/close-button.svg) !important;
}

.notify-success button{
  background-image: url(./assets/images/success-close-button.svg) !important;
}

.toast-info button{
  background-image: url(./assets/images/informational-close-button.svg) !important;
}

.notify-error button span, .notify-success button span, .toast-info button span{
  visibility: hidden;
}

.dxp-no-wrap {
  white-space: nowrap;
}

.dxp .dxp-theme-white.dxp-input .error-circle-red {
  padding-left: 5px;
}

.ag-grid .ag-grid-angular {
     width: 100%;
     height: 60vh;
}
.ag-theme-alpine {
  font-family: MarkForMC, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial,
  sans-serif;
}
.ag-header-cell-text {
  font-weight: bolder;
}
.action-icon {
  margin: 5px;
  color: #cf4500;
  font-size: 20px;
  cursor: pointer;
}
.more-icon {
  color: #cf4500;
  font-size: inherit;
  cursor: pointer;
}
.grid-primary-text {
  color: #cf4500 !important;
  font-weight: bolder;
}

.ag-cell-wrap-text {
  white-space: normal;
  word-break: break-word;
}

.ag-theme-alpine .ag-root-wrapper {
  border: none;
}

.ag-root-wrapper a {
  cursor: pointer;
}

/* card css */
.eth-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}

.eth-card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #00000008;
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.eth-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
